<template>
  <EntitySingle :config="config" :entity.sync="entity" :loaded.sync="loaded">
    <b-card v-if="loaded">
      <b-tabs pills>
        <!-- Tab: Info -->
        <b-tab active>
          <template #title>
            <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFields"
            allow-autofocus
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'

import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'

import storeModule from '../productCategoryStoreModule'

import typeOptions from '../typeOptions'
import axios from '@/libs/axios'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    EntitySingle,
    EntityEditSection,
  },
  data() {
    return {
      entity: {
        type: '',
        categoryParent: '',
        subCategoryParent: '',
        attributeParent: '',
      },
      loaded: false,
      userOptions: [],
      categoryOptions: [],
      subCategoryOptions: [],
      attributeOptions: [],
      initialLoadType: true,
      initialLoadCategory: true,
      initialLoadSubCategory: true,
    }
  },
  computed: {
    entityType() {
      return this.entity.type
    },
    entityCategory() {
      return this.entity.categoryParent && this.entity.categoryParent.id
    },
    entitySubCategory() {
      return this.entity.subCategoryParent && this.entity.subCategoryParent.id
    },
    config() {
      return {
        store: storeModule,
        module: 'category',
        endpoint: 'category',
        route: 'product-categories',
        title: {
          single: this.$i18n.t('Product Category'),
          plural: this.$i18n.t('Product Categories'),
        },
      }
    },

    infoFields() {
      const fields = [
        {
          id: 'type',
          type: 'select',
          label: this.$t('Select Type'),
          options: typeOptions,
          required: true,
          colSize: 2,
        },
      ]
      if (
        this.entity.type == 'SUB_CATEGORY' ||
        this.entity.type == 'ATTRIBUTE' ||
        this.entity.type == 'SUB_ATTRIBUTE'
      ) {
        fields.push({
          id: 'categoryParent',
          type: 'select',
          object: true,
          label: this.$t('product category'),
          options: this.categoryOptions,
          required: true,
          colSize: 3,
        })
        if (
          this.entity.type == 'ATTRIBUTE' ||
          this.entity.type == 'SUB_ATTRIBUTE'
        ) {
          fields.push({
            id: 'subCategoryParent',
            type: 'select',
            object: true,
            label: this.$t('productSubCategory'),
            options: this.subCategoryOptions,
            required: true,
            colSize: 3,
          })
          if (this.entity.type == 'SUB_ATTRIBUTE')
            fields.push({
              id: 'attributeParent',
              type: 'select',
              object: true,
              label: this.$t('prodAttr'),
              options: this.attributeOptions,
              required: true,
              colSize: 3,
            })
        }
      }
      fields.push({
        id: 'title',
        type: 'text',
        label: this.$t('Title'),
        required: true,
        colSize: 3,
      })
      return fields
    },
  },
  watch: {
    entityType: function () {
      if (this.entity.type) {
        // console.log(
        //   'entering entityType():',
        //   this.entity.type,
        //   ', this.entity initial:',
        //   this.entity
        // )
        if (this.initialLoadType !== true)
          this.entity.categoryParent =
            this.entity.subCategoryParent =
            this.entity.attributeParent =
            this.entity.title =
              ''
        this.subCategoryOptions = this.attributeOptions = []
        if (this.entity.type == 'CATEGORY') {
          delete this.entity.categoryParent
          delete this.entity.subCategoryParent
          delete this.entity.attributeParent
        } else if (this.entity.type == 'SUB_CATEGORY') {
          delete this.entity.subCategoryParent
          delete this.entity.attributeParent
        } else if (this.entity.type == 'ATTRIBUTE') {
          delete this.entity.attributeParent
        }
        this.initialLoadType = false
      } else {
        this.entity.categoryParent =
          this.entity.subCategoryParent =
          this.entity.attributeParent =
          this.entity.title =
            ''
      }
    },
    entityCategory: function () {
      if (this.entity.categoryParent) {
        // console.log(
        //   'Entering into entityCategory():',
        //   this.entity.categoryParent
        // )
        if (this.initialLoadCategory !== true)
          this.entity.subCategoryParent = this.entity.attributeParent = ''
        this.subCategoryOptions = this.attributeOptions = []
        if (this.entity.type == 'SUB_CATEGORY') {
          delete this.entity.subCategoryParent
          delete this.entity.attributeParent
        } else if (this.entity.type == 'ATTRIBUTE') {
          delete this.entity.attributeParent
        }
        let categoryId = this.entity.categoryParent.id
        if (categoryId != '' && this.entity.type !== 'SUB_CATEGORY') {
          axios
            .get('categories/list/all-sub-categories', {
              params: { categoryId },
            })
            .then((response) => {
              this.subCategoryOptions = response.data
              if (this.subCategoryOptions.length == 0)
                this.attributeOptions = []
            })
        }
        this.initialLoadCategory = false
      } else {
        this.entity.subCategoryParent = this.entity.attributeParent = ''
        if (this.entity.type == 'CATEGORY') {
          delete this.entity.subCategoryParent
          delete this.entity.attributeParent
        }
        this.subCategoryOptions = this.attributeOptions = []
      }
    },
    entitySubCategory: function () {
      if (this.entity.subCategoryParent) {
        // console.log(
        //   'Entering into entitySubCategory():',
        //   this.entity.subCategoryParent
        // )
        if (this.initialLoadSubCategory !== true)
          this.entity.attributeParent = ''
        this.attributeOptions = []
        let categoryId = this.entity.subCategoryParent.id
        if (this.entity.type == 'ATTRIBUTE') {
          delete this.entity.attributeParent
        }
        if (categoryId != '' && this.entity.type !== 'ATTRIBUTE') {
          axios
            .get('categories/list/attributes', {
              params: { categoryId },
            })
            .then((response) => {
              this.attributeOptions = response.data
            })
        }
        this.initialLoadSubCategory = false
      } else {
        this.entity.attributeParent = ''
        if (this.entity.type !== 'SUB-ATTRIBUTE')
          delete this.entity.attributeParent
        this.attributeOptions = []
      }
    },
  },
  mounted() {
    axios.get('categories/list/all-categories').then((response) => {
      this.categoryOptions = response.data
    })
  },
}
</script>
